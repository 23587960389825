import React, { useContext, useState } from "react";
import { Box, HStack, Image } from "@chakra-ui/react";
import _ from "lodash";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AppContext from "AppContext";

const items = [
  {
    desktop: require("assets/banners/img-01-desktop.webp"),
    mobile: require("assets/banners/img-01-mobile.webp"),
  },
];

const Banners = () => {
  const { isMobileView } = useContext(AppContext);
  const [activeIndex, setActiveIndex] = useState(0);

  return isMobileView ? (
    <Box w="100%" h="180px" />
  ) : (
    <Box id="home" position="relative" marginTop="-4%">
      <Carousel
        selectedItem={activeIndex}
        onChange={(index) => setActiveIndex(index)}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        emulateTouch={true}
        infiniteLoop={true}
      >
        {items.map((item, index) => (
          <div key={index.toString()}>
            <Image alt="Tenha desconto na conta de luz com um click" src={isMobileView ? item.mobile : item.desktop} />
          </div>
        ))}
      </Carousel>
      {_.size(items) > 1 && (
        <HStack justifyContent="center" position="absolute" bottom="160px" w="100%">
          {items.map((item, index) => (
            <Box
              key={index.toString()}
              w="10px"
              h="10px"
              borderRadius="full"
              bg="white"
              cursor="pointer"
              opacity={index === activeIndex ? "1" : "0.5"}
              onClick={() => setActiveIndex(index)}
            />
          ))}
        </HStack>
      )}
    </Box>
  );
};

export default Banners;
