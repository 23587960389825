import React, { useEffect, useState, useCallback } from "react";
import { Flex, Text, Icon, Modal, ModalOverlay, ModalContent, ModalBody, Button, Heading } from "@chakra-ui/react";
import { MdOutlineCloudDownload } from "react-icons/md";
import { EventEmitter } from "lib";

export const AppVersionError = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState();

  useEffect(() => {
    EventEmitter.subscribe("onAppVersionError", (error) => setMessage(error.message));
  }, []);

  const handleRefresh = useCallback(async () => {
    try {
      setIsLoading(true);
      if ("caches" in window) {
        const names = await caches.keys();
        for (const name of names) await caches.delete(name);
      }
    } finally {
      window.location.reload(true);
    }
  }, []);

  return (
    <Modal isOpen={message} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody as={Flex} direction="column" justifyContent="center" alignItems="center" padding="30px">
          <Heading size="lg">Atualização necessária</Heading>
          <Text fontSize="sm" textAlign="center" mb="20px">
            {message}
          </Text>
          <Button
            aria-label="Atualizar"
            colorScheme="main"
            rightIcon={<Icon as={MdOutlineCloudDownload} />}
            isLoading={isLoading}
            onClick={handleRefresh}
          >
            Atualizar
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
