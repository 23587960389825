import React from "react";
import { SimpleGrid, Link, Box, Text, Center, HStack, IconButton, Icon, VStack, Button } from "@chakra-ui/react";
import { Logo } from "components";
import { WhatsAppButton } from "../Header";
import { FaEnvelope, FaFacebookSquare, FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { HiPhone } from "react-icons/hi";

export const Footer = () => {
  return (
    <Center bg="#F6F6F6">
      <Box maxW="1300px" w="100%" p="40px">
        <SimpleGrid columns={[1, 4]} py="60px">
          <Box>
            <Logo width={250} />
            <HStack py="20px" spacing="5px">
              <IconButton
                aria-label="Facebook ClickLivre"
                size="lg"
                variant="outline"
                target="_blank"
                icon={<Icon as={FaFacebookSquare} />}
                onClick={() => window.open("https://www.facebook.com/clicklivre.energia")}
                isRound
              />
              <IconButton
                aria-label="Instagram ClickLivre"
                size="lg"
                variant="outline"
                target="_blank"
                icon={<Icon as={FaInstagram} />}
                onClick={() => window.open("https://www.instagram.com/clicklivre_energia")}
                isRound
              />
              <IconButton
                aria-label="Twitter ClickLivre"
                size="lg"
                variant="outline"
                target="_blank"
                icon={<Icon as={FaTwitter} />}
                onClick={() => window.open("https://www.twitter.com/clicklivre")}
                isRound
              />
              <WhatsAppButton size="lg" variant="outline" colorScheme="gray" bg="transparent" />
            </HStack>
          </Box>
          <VStack alignItems="flex-start">
            <Text color="gray.600">Rua dos Goitacazes, 15 - 901 - Centro, Belo Horizonte - MG, CEP 30190-050</Text>
            <Link href="mailto:contato@clicklivre.com.br" _hover={{ textDecoration: "none", color: "main.500" }}>
              <HStack>
                <Center w="26px" h="26px" borderRadius="full" bg="gray.500">
                  <Icon as={FaEnvelope} boxSize="12px" color="white" />
                </Center>
                <Text>contato@clicklivre.com.br</Text>
              </HStack>
            </Link>
            <Link href="https://wa.me/5531989569405" target="_blank" _hover={{ textDecoration: "none", color: "main.500" }}>
              <HStack>
                <Center w="26px" h="26px" borderRadius="full" bg="main.500">
                  <Icon as={FaWhatsapp} color="white" />
                </Center>
                <Text>(31) 9 8956-9405</Text>
              </HStack>
            </Link>
            <Link href="tel:+553136534993" _hover={{ textDecoration: "none", color: "main.500" }}>
              <HStack>
                <Center w="26px" h="26px" borderRadius="full" bg="blue.500">
                  <Icon as={HiPhone} color="white" />
                </Center>
                <Text>(31) 3653-4993</Text>
              </HStack>
            </Link>
            <Box h={3} />
            <Button
              as={Link}
              href="https://cliente.clicklivre.com.br"
              borderRadius="full"
              color="white"
              bg="secondary.500"
              w="100%"
              _hover={{ bg: "secondary.700", color: "white" }}
            >
              Área do cliente
            </Button>
          </VStack>
          <Box />
          <Box />
        </SimpleGrid>
        <VStack spacing={0}>
          <Text>&copy; Todos os direitos reservados | ClickLivre {new Date().getFullYear()}.</Text>
          <Text fontSize="xs">v{process.env.REACT_APP_VERSION}</Text>
        </VStack>
      </Box>
    </Center>
  );
};
