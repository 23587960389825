import React, { Fragment } from "react";
import { HashLink } from "react-router-hash-link";
import { Box, Grid, GridItem, HStack, Image, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { ClickRecButton } from "pages/ClickRec";

const ClickRec = () => {
  return (
    <Fragment>
      <Box
        display={{ base: "block", lg: "none" }}
        backgroundImage={require("assets/click-rec/img-01.webp")}
        backgroundPosition="right"
        backgroundSize="auto 115%"
        w="100%"
        h="100vw"
      />

      <HStack
        alignItems="center"
        justifyContent="center"
        backgroundImage={require("assets/click-rec/img-01.webp")}
        backgroundPosition={{ base: "center center", lg: "center right" }}
      >
        <Grid
          templateColumns="repeat(12, 1fr)"
          maxW="1440px"
          h={{ base: "auto", lg: "700px" }}
          p="40px"
          alignItems="center"
          justifyContent="center"
        >
          <GridItem as={VStack} spacing={4} alignItems={{ base: "center", lg: "flex-start" }} colSpan={{ base: 12, lg: 6 }} color="white">
            <Image alt="Selo Click Rec" src={require("assets/click-rec/logo.webp")} w="100%" />
            <Text fontSize="2xl">CERTIFICADO DE ENERGIA RENOVÁVEL</Text>
            <Text>
              Certificado de Energia Renovável (Renewable Energy Certificate) que comprova que a sua empresa utiliza em seu processo
              produtivo energia limpa e 100% renovável.
            </Text>
            <ClickRecButton as={HashLink} to="/click-rec#solicite" />
          </GridItem>
        </Grid>
      </HStack>
    </Fragment>
  );
};

export default ClickRec;
