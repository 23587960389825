import React, { useState, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { HashLink } from "react-router-hash-link";
import InputMask from "react-input-mask";
import BrazilPhoneInput from "react-telefone-brasileiro";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Input,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { messages } from "consts";
import { yup, api } from "lib";

export const ClickRecButton = (props) => {
  return (
    <Button
      aria-label="Solicitar Click Rec"
      colorScheme="whiteAlpha"
      backgroundImage={require("assets/click-rec/button.webp")}
      borderRadius="40px"
      backgroundSize="100% 100%"
      size="lg"
      _hover={{ backgroundImage: require("assets/click-rec/button-hover.webp") }}
      _active={{ backgroundImage: require("assets/click-rec/button-hover.webp"), opacity: 0.6 }}
      {...props}
    >
      SOLICITAR CLICK REC
    </Button>
  );
};

export const ClickRec = () => {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.put("/public/click-rec", data);
        setFormData({});
        setFormErrors({});
        toast({ description: "Solicitação enviada com sucesso!", status: "success", isClosable: true });
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [toast]
  );

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const schema = yup.object().shape({
          name: yup.string().required(messages.error.required),
          document: yup.string().required(messages.error.required),
          email: yup.string().email(messages.error.invalidEmail).required(messages.error.required),
          phone: yup.string().required(messages.error.required),
        });
        await schema.validate(formData, { abortEarly: false });
        handleSaveData(formData);
        setFormErrors({});
      } catch (error) {
        const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
        setFormErrors(formErrors);
      }
    },
    [formData, handleSaveData]
  );

  return (
    <>
      <Helmet>
        <title>Click Rec</title>
      </Helmet>

      <Box
        display={{ base: "block", lg: "none" }}
        backgroundImage={require("assets/click-rec/img-01.webp")}
        backgroundPosition="right"
        backgroundSize="auto 115%"
        w="100%"
        h="100vw"
      />

      <HStack
        alignItems="center"
        justifyContent="center"
        backgroundImage={require("assets/click-rec/img-01.webp")}
        backgroundPosition={{ base: "center center", lg: "center right" }}
      >
        <Grid
          templateColumns="repeat(12, 1fr)"
          maxW="1440px"
          h={{ base: "auto", lg: "700px" }}
          p="40px"
          alignItems="center"
          justifyContent="center"
        >
          <GridItem as={VStack} spacing={4} alignItems={{ base: "center", lg: "flex-start" }} colSpan={{ base: 12, lg: 6 }} color="white">
            <VStack alignItems={{ base: "center", lg: "flex-start" }} spacing={0}>
              <Text>Bom para você, bom para o seu cliente, </Text>
              <Text mb="10px" fontSize="lg">
                melhor para o mundo!
              </Text>
            </VStack>
            <Image alt="Selo Click Rec" src={require("assets/click-rec/logo.webp")} w="100%" />
            <Text>
              Certificado de Energia Renovável (Renewable Energy Certificate) que comprova que a sua empresa utiliza em seu processo
              produtivo energia limpa e 100% renovável.
            </Text>
            <ClickRecButton as={HashLink} to="#solicite" />
          </GridItem>
        </Grid>
      </HStack>

      <HStack justifyContent="center" bg="#f8f8f8">
        <Grid templateColumns="repeat(12, 1fr)" maxW="1440px">
          <GridItem as={VStack} justifyContent="center" alignItems="center" colSpan={{ base: 12, lg: 5 }} p="40px">
            <Image alt="Selo Click Rec" w="100%" maxW="400px" src={require("assets/click-rec/selo-click-rec.webp")} />
          </GridItem>
          <GridItem as={VStack} justifyContent="center" alignItems="stretch" colSpan={{ base: 12, lg: 7 }} p="40px">
            <Heading fontWeight="light" color="#2bb23b">
              Selo CLICK REC de Energia Renovável
            </Heading>
            <Box h="20px" />
            <Text>
              O Selo CLICK REC de Energia 100% Renovável é de uso exclusivo das empresas clientes Click Livre que possuem o Certificado
              Click REC. O Selo Click REC pode ser usado em suas embalagens, processos produtivos, pontos de venda e demais locais
              estratégicos para comunicar aos clientes e à sociedade sobre o uso de energia limpa e 100% renovável em seu processo produtivo
              e funcionamento da empresa.
            </Text>
            <Text>
              Além da sinalização do cumprimento das políticas sustentáveis por parte da empresa, visa fortalecer as marcas das empresas que
              os adquirem e as destacam ante a concorrência. Trata-se de uma tendência global irreversível, de certa forma, obrigatória para
              organizações que visam a perenidade de seus negócios e adequação a esse novo modelo econômico.
            </Text>
          </GridItem>
        </Grid>
      </HStack>

      <HStack id="solicite" alignItems="center" justifyContent="center" py="60px">
        <Grid templateColumns="repeat(12, 1fr)" w="100%" maxW="1440px">
          <GridItem as={VStack} justifyContent="center" colSpan={{ base: 12, lg: 5 }}>
            <VStack spacing={0} mb="20px">
              <Heading size="lg" fontWeight="light" color="#034a3a">
                SOLICITE O CLICK REC
              </Heading>
              <Text color="#034a3a">CERTIFICADO DE ENERGIA RENOVÁVEL</Text>
            </VStack>
            <VStack w="100%" maxW="80%" spacing={3}>
              <FormControl isRequired={true} isInvalid={formErrors.name}>
                <Input
                  size="lg"
                  placeholder="NOME"
                  value={formData.name || ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))}
                  borderColor="#2bb23b"
                  borderWidth="2px"
                  _hover={{ borderColor: "#2bb23b" }}
                  borderRadius="40px"
                />
                <FormErrorMessage>{formErrors.name}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired={true} isInvalid={formErrors.document}>
                <Input
                  size="lg"
                  placeholder="CNPJ"
                  as={InputMask}
                  mask="99.999.999/9999-99"
                  value={formData.document || ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
                  borderColor="#2bb23b"
                  borderWidth="2px"
                  _hover={{ borderColor: "#2bb23b" }}
                  borderRadius="40px"
                />
                <FormErrorMessage>{formErrors.document}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired={true} isInvalid={formErrors.email}>
                <Input
                  size="lg"
                  placeholder="E-MAIL"
                  value={formData.email || ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, email: target.value }))}
                  borderColor="#2bb23b"
                  borderWidth="2px"
                  _hover={{ borderColor: "#2bb23b" }}
                  borderRadius="40px"
                />
                <FormErrorMessage>{formErrors.email}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired={true} isInvalid={formErrors.phone}>
                <Input
                  as={BrazilPhoneInput}
                  size="lg"
                  placeholder="TELEFONE"
                  value={formData.phone || ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, phone: target.value }))}
                  borderColor="#2bb23b"
                  borderWidth="2px"
                  _hover={{ borderColor: "#2bb23b" }}
                  borderRadius="40px"
                  temDDD
                  separaDDD
                />
                <FormErrorMessage>{formErrors.phone}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired={true} isInvalid={formErrors.contactName}>
                <Input
                  size="lg"
                  placeholder="NOME CONTATO"
                  value={formData.contactName || ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, contactName: target.value }))}
                  borderColor="#2bb23b"
                  borderWidth="2px"
                  _hover={{ borderColor: "#2bb23b" }}
                  borderRadius="40px"
                />
                <FormErrorMessage>{formErrors.contactName}</FormErrorMessage>
              </FormControl>
              <ClickRecButton onClick={handleSubmit} isLoading={isLoadingSaveData} />
            </VStack>
          </GridItem>
          <GridItem as={VStack} colSpan={{ base: 12, lg: 7 }}>
            <Image alt="Selo Click Rec" src={require("assets/click-rec/logo.webp")} w="80%" mt={{ base: "60px", lg: "0" }} mb="20px" />
            <VStack textAlign="center" maxW="400px" spacing={4}>
              <Text>
                Ao solicitar o CLICK REC, você concorda em ajudar o planeta e se compromete a usar em seu processo produtivo energia limpa e
                100% renovável ClickLivre.
              </Text>
              <Text>
                Nossa energia é o futuro renovável. Nossa missão é levar economia até você, através de uma conta de energia mais barata e
                sustentável.
              </Text>
              <Text>
                A ClickLivre proporciona redução na conta de luz de seus clientes através da geração de energia de fontes renováveis.
              </Text>
            </VStack>
          </GridItem>
        </Grid>
      </HStack>

      <Box
        display={{ base: "block", lg: "none" }}
        backgroundImage={require("assets/click-rec/img-02.webp")}
        backgroundPosition="-10vw center"
        backgroundSize="auto 100%"
        w="100%"
        h="100vw"
      />

      <HStack
        justifyContent="center"
        backgroundImage={require("assets/click-rec/img-02.webp")}
        backgroundPosition={{ base: "center right", lg: "center center" }}
      >
        <Grid templateColumns="repeat(12, 1fr)" h={{ base: "auto", lg: "100vh" }} maxW="1440px">
          <GridItem colSpan={{ base: 12, lg: 6 }}></GridItem>
          <GridItem as={VStack} justifyContent="center" alignItems="stretch" colSpan={{ base: 12, lg: 6 }} p="40px">
            <Heading fontWeight="light" color="#2bb23b" mb="20px">
              Quais os Benefícios?
            </Heading>
            <VStack justifyContent="center" alignItems="stretch" spacing={4} color="white">
              <HStack spacing={4}>
                <Box w="5px" bg="#2bb23b" borderRadius="5px" h="80px" />
                <Text flex="1">Demonstra o compromisso com as melhores práticas Governança Ambiental, Social e Corporativa (ESG);</Text>
              </HStack>
              <HStack spacing={4}>
                <Box w="5px" bg="#2bb23b" borderRadius="5px" h="80px" />
                <Text flex="1">
                  Garante conformidade com padrões de emissões e protocolos ambientais, como GHG Protocol, CDP entre outros;
                </Text>
              </HStack>
              <HStack spacing={4}>
                <Box w="5px" bg="#2bb23b" borderRadius="5px" h="80px" />
                <Text flex="1">Reforça o compromisso com a preservação ambiental, e redução das emissões de Co2 na atmosfera;</Text>
              </HStack>
              <HStack spacing={4}>
                <Box w="5px" bg="#2bb23b" borderRadius="5px" h="80px" />
                <Text flex="1">Ferramenta completa de rastreabilidade da geração renovável de energia.</Text>
              </HStack>
            </VStack>
          </GridItem>
        </Grid>
      </HStack>

      <HStack
        justifyContent="center"
        backgroundImage={require("assets/click-rec/img-03.webp")}
        backgroundPosition={{ base: "center right", lg: "center center" }}
      >
        <Grid templateColumns="repeat(12, 1fr)" h={{ base: "auto", lg: "363px" }} maxW="1440px">
          <GridItem as={VStack} justifyContent="center" alignItems="stretch" colSpan={{ base: 12, lg: 6 }} p="40px">
            <Heading fontWeight="light" color="white" mb="20px">
              Mostre ao mundo a consciência ambiental da sua empresa!
            </Heading>
          </GridItem>
          <GridItem as={VStack} justifyContent="center" alignItems="center" colSpan={{ base: 12, lg: 6 }} pb={{ base: "60px", lg: "0" }}>
            <ClickRecButton as={HashLink} to="#solicite" />
          </GridItem>
        </Grid>
      </HStack>
    </>
  );
};
