import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, SimpleGrid, Image, Button, HStack, VStack, Text, Heading } from "@chakra-ui/react";
import _ from "lodash";

const QuemSomos = () => {
  return (
    <SimpleGrid id="quem-somos" columns={{ base: 1, lg: 2 }} bg="secondary.500">
      <HStack justifyContent="flex-end">
        <VStack p={{ base: "40px 20px", lg: "0 60px 0 0" }} w={{ base: "100%", lg: "650px" }} justifyContent="center" alignItems="stretch">
          <Heading size="2xl" color="white">
            Quem somos
          </Heading>
          <Box h="20px" />
          <Text color="white" fontSize="lg" lineHeight="10">
            Nós somos a mudança, somos um futuro renovável. E nossa missão é levar economia até você, através de uma conta de energia mais
            barata e sustentável.
          </Text>
          <Text color="white" fontSize="lg" lineHeight="10">
            A ClickLivre proporciona redução na conta de luz de seus clientes através da geração de energia de fontes renováveis. Nossos
            planos de assinatura trazem liberdade de escolha associada à preservação do meio ambiente.
          </Text>
          <Box h="20px" />
          <HStack>
            <Button
              aria-label="Quero economizar"
              as={RouterLink}
              to="/quero-economizar"
              size="lg"
              w={{ base: "100%", lg: "auto" }}
              colorScheme="main"
            >
              QUERO ECONOMIZAR!
            </Button>
          </HStack>
        </VStack>
      </HStack>
      <Box w="100%">
        <Image alt="Quem somos" w="100%" src={require("assets/imgs/quem-somos/bg.webp")} />
      </Box>
    </SimpleGrid>
  );
};

export default QuemSomos;
