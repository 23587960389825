import React, { useState, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import _ from "lodash";
import { messages } from "consts";
import { yup, api } from "lib";
import { RightPageContainer } from "components";
import Lottie from "react-lottie";
import animationData from "assets/lottie/smartphone.json";
import { TbCloudDownload } from "react-icons/tb";

export const IndicationsGenerate = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const state = await api.post(`/public/indications/${data.phone}`);
        if (!state) onOpen();
        else navigate("/indique-e-ganhe/compartilhe", { state });
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [toast]
  );

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const schema = yup.object().shape({
          phone: yup.string().required(messages.error.required),
        });
        await schema.validate(formData, { abortEarly: false });
        handleSaveData(formData);
        setFormErrors({});
      } catch (error) {
        const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
        setFormErrors(formErrors);
      }
    },
    [formData, handleSaveData]
  );

  return (
    <>
      <Helmet>
        <title>Indique e Ganhe</title>
      </Helmet>
      <RightPageContainer bgSrc="linear-gradient(to right, #00513E, #00462A, #002D13)" fgSrc={require("assets/imgs/sign-up/fg-04.webp")}>
        <VStack flex="1" alignItems="stretch" justifyContent="center" spacing="15px" py="20px">
          <Lottie
            options={{ loop: true, autoplay: true, animationData }}
            isClickToPauseDisabled={true}
            width={200}
            height={200}
            style={{ cursor: "default" }}
          />
          <Heading size="md">Informe seu celular</Heading>
          <Text fontSize="sm" mb="10px">
            Digite seu celular no campo abaixo para obter seu link de indicação.
          </Text>
          <form onSubmit={handleSubmit}>
            <FormControl isRequired={true} isInvalid={formErrors.phone}>
              <HStack>
                <Input
                  as={InputMask}
                  inputMode="numeric"
                  mask="(99) 99999-9999"
                  value={formData.phone ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, phone: target.value }))}
                />
                <Button aria-label="Enviar" type="submit" colorScheme="main" isLoading={isLoadingSaveData}>
                  Enviar
                </Button>
              </HStack>
              <FormErrorMessage>{formErrors.phone}</FormErrorMessage>
            </FormControl>
          </form>
        </VStack>
        <Text fontSize="sm" textAlign="center">
          Confira nosso regulamento e comece a economizar ainda mais!
        </Text>
        <Button
          aria-label="Baixar regulamento"
          as={Link}
          href="/downloads/regulamento-click-club.pdf"
          target="_blank"
          leftIcon={<Icon as={TbCloudDownload} />}
        >
          Baixar regulamento
        </Button>
      </RightPageContainer>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay>
          <ModalContent>
            <ModalBody>
              <VStack spacing="0" p="30px">
                <Heading size="lg" textAlign="center">
                  Cadastro não encontrado
                </Heading>
                <Text fontSize="sm" textAlign="center">
                  Você ainda não possui um cadastro associado a este celular. Somente clientes cadastrados podem participar do programa de
                  indicações.
                </Text>
                <Box h="20px" />
                <Button aria-label="Iniciar cadastro" as={RouterLink} to="/quero-economizar" colorScheme="main">
                  Iniciar cadastro
                </Button>
              </VStack>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};
